import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeroImage from "../../../assets/instructors/sifutony/24WingChunAcademyLR-10.jpg";
import {Link} from "react-router-dom";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FF2E28",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "montserratbold",
                    fontSize: '1rem',
                    padding: '10px 28px',
                    borderRadius: '4',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        zIndex: '999',
        backgroundColor: '#0D0D0D',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
    },
    effect: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(90deg, #614F41, #181A17)',
    },
    panelContent: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            width: '862px',
        },

        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            width: '1082px',
        }
    },
    photoContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '48px 36px 0px 36px',

        [theme.breakpoints.up('md')]: {
            padding: '76px 46px 0px 46px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '76px 0px 64px 50px',
        },
    },
    photo: {
        width: '100%',

        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('lg')]: {
            width: '482px',
        },
    },
    textContent: {
        textAlign: 'left',
        padding: '16px 16px 26px 16px',

        [theme.breakpoints.up('md')]: {
            padding: '46px 0px 46px 0px',
        },

        [theme.breakpoints.up('lg')]: {
            padding: '64px 0px 64px 66px',
        },
    },
    subtitle: {
        fontFamily: 'montserratbold',
        letterSpacing: '0.1em',
        margin: '12px 0',
        textTransform: 'uppercase',
        color: '#FFF',
    },
    text: {
        margin: '0 0 20px 0',
        color: '#FFF',
    },
    more: {
        margin: '12px 0',

        '& a': {
            padding: '2px 4px',
            fontFamily: 'montserratbold',
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
            color: '#FFF',
            textDecoration: 'none',
            background: 'linear-gradient(0, #614F41 50%, transparent 50%)',
        },
        '& a:hover': {
            background: 'none',
        }
    },
});

class SifuTonyPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        let self = this;

        return (
            <ThemeProvider theme={muiTheme}>

            <div className={classes.panel}>
                <div className={classes.effect}>

                    <div className={classes.panelContent}>

                    <div className={classes.content}>
                        <div className={classes.photoContent}>
                            <div className={classes.photo}><img src={HeroImage} /></div>
                        </div>

                        <div className={classes.textContent}>
                            <div className={classes.subtitle}>Tony’s life revolved around training and teaching for the school and in 1984, he worked full time at the school as an assistant manager and taught classes at the school’s head office and its various branches. Tony ran workshops and courses for many private and public schools and various private organizations during this time.</div>
                            <div className={classes.text}>
                                Tony took a hiatus from wing chun in 1990 to focus on his Christian faith and to go back to study and complete his university degree.   In 1996, he returned and immediately took on a teaching role.  During this time, Tony’s predominant focus in teaching and practice was the cultivation of an internal and relaxed structure and its maintenance during execution.  Tony was encouraged continuously by his Sifu and Sigung (Master Chu Shong Tin) to further develop this and it remains the predominant focus of his teaching and personal training.
                                <br /><br />
                                Tony continues to give workshops and seminars in SA, NSW and QLD and occasionally visits overseas to further his training and share his experience and understanding of wing chun with others.
                                <br /><br />
                                In 2013, the International Wing Chun Academy Adelaide branch closed its doors. Two months later in the same year, Tony and fellow instructors Damian Rainer and Nick Bennett opened their own school, Adelaide Wing Chun Academy, where he continues to teach, share his passion, and his understanding of wing chun kung fu.
                            </div>

                            <div className={classes.more}><Link to="/sifudamian">Read about Sifu Damian Rainer</Link></div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            </ThemeProvider>
        )
    }
}

export default withStyles(useStyles)(SifuTonyPanel)