import React from 'react';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import WelcomePage from './components/WelcomePage';
import AboutPage from './components/Pages/About/AboutPage';
import ClassesPage from './components/Pages/Classes/ClassesPage';
import DragonsClassesPage from './components/Pages/ClassesDragons/DragonsClassesPage';
import GalleryPage from './components/Pages/Gallery/GalleryPage';
import ContactPage from './components/Pages/Contact/ContactPage';
import FreeLessonPage from './components/Pages/FreeLesson/FreeLessonPage';
import SifusPage from './components/Pages/Instructors/SifusPage';
import SifuTonyPage from './components/Pages/Instructors/SifuTonyPage';
import SifuDamianPage from './components/Pages/Instructors/SifuDamianPage';
import SpecialPage from './components/Pages/Special/SpecialPage';
import OfferPage from './components/Pages/Offer/OfferPage';
import MembershipPage from './components/Pages/Membership/MembershipPage';
import MembershipThanksPage from './components/Pages/Membership/MembershipThanksPage';

import './cufonfonts.css';
import './stylesheet.css';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={WelcomePage} />
          <Route path="/about" exact component={AboutPage} />
          <Route path="/classes" exact component={ClassesPage} />
          <Route path="/dragonsclasses" exact component={DragonsClassesPage} />
          <Route path="/instructors" exact component={SifusPage} />
          <Route path="/sifutony" exact component={SifuTonyPage} />
          <Route path="/sifudamian" exact component={SifuDamianPage} />
          <Route path="/gallery" exact component={GalleryPage} />
          <Route path="/contact" exact component={ContactPage} />
          <Route path="/freelesson" exact component={FreeLessonPage} />
          <Route path="/offer" exact component={OfferPage} />
          <Route path="/special" exact component={SpecialPage} />
          <Route path="/membership" exact component={MembershipPage} />
          <Route path="/membershipthanks" exact component={MembershipThanksPage} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
